<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('sending_sms_to_staff')" @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('sending_sms_to_staff')" @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('surname')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.surname"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('national_number')">
                            <lined-textarea v-model="datatable.queryParams.filter.national_id"
                                            :nowrap="false"
                                            :disabled="false"
                                            :styles="{ height: '10em', resize: 'both' }"
                            >
                            </lined-textarea>
                        </b-form-group>


                        <b-form-group :label="$t('email')">
                            <b-form-input type="email" size="sm"
                                          v-model="datatable.queryParams.filter.email"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"></program-selectbox>
                        </b-form-group>
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="datatable.queryParams.filter.department_code"
                                                  :faculty_code="datatable.queryParams.filter.faculty_code"/>
                        </b-form-group>

                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('contract_type')">
                            <parameter-selectbox code="contract_types"
                                                 v-model="datatable.queryParams.filter.contract_type"/>
                        </b-form-group>
                        <b-form-group :label="$t('roles')">
                            <ValidationProvider name="roles" rules="">
                                <role-selectbox :multiple="true" v-model="datatable.queryParams.filter.roles"/>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group :label="$t('working_status')">
                            <parameter-selectbox code="working_statuses"
                                                 v-model="datatable.queryParams.filter.working_status"/>
                        </b-form-group>
                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable ::isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                       @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>


            <b-button class="mt-4" @click="openModal" :disabled="showButton">
                {{ $t('continue') }}
            </b-button>

            <CommonModal ref="showModal" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('send_sms').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="form">
                        <b-col cols="12" md="12" lg="12" xl="12">
                            <ValidationProvider name="originator" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('originator')">
                                    <parameter-selectbox
                                        code="originators_code"
                                        v-model="form.originator">
                                    </parameter-selectbox>
                                </b-form-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                            <ValidationProvider name="description" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('explanation')">
                                    <b-form-input v-model="form.description"></b-form-input>
                                </b-form-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </ValidationProvider>

                            <ValidationProvider name="message" :rules="form.message_en ?'':'required'" v-slot="{ valid, errors }">
                                <b-input-group prepend="TR" class="mb-2">
                                    <b-form-textarea
                                        id="textarea-rows"
                                        v-model="form.message"
                                        rows="10"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </ValidationProvider>
                            <ValidationProvider name="message_en" :rules="form.message ?'':'required'" v-slot="{ valid, errors }">

                                <b-input-group prepend="EN">
                                    <b-form-textarea
                                        id="textarea-rows"
                                        v-model="form.message_en"
                                        rows="10"
                                    />
                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </ValidationProvider>


                            <b-button variant="primary" class="mt-4" @click="sendSms()">
                                {{ $t('send_sms') }}
                            </b-button>

                        </b-col>
                    </ValidationObserver>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import qs from "qs";
import sendSmsServices from "@/services/sendSmsServices";

export default {
    components: {
        LinedTextarea,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t('sending_sms_to_staff')
        }
    },
    watch: {
        'datatable.rows'(value) {
            value.length > 0 ? this.showButton = false : this.showButton = true
        }

    },
    data() {
        return {
            showButton: true,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.$t('name'),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.$t('email'),
                        field: 'email',
                        sortable: false,
                    },
                    {
                        label: this.$t('faculty'),
                        field: 'faculty_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('program'),
                        field: 'program_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('working_status'),
                        field: 'working_status',
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                        name: null,
                        surname: null,
                        national_id: null,
                        email: null,
                        faculty_code: null,
                        department_code: null,
                        program_code: null,
                        contract_type: null,
                        roles: null,
                        working_status: null,
                    },
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            form: {}
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                name: null,
                surname: null,
                national_id: null,
                email: null,
                faculty_code: null,
                department_code: null,
                program_code: null,
                contract_type: null,
                roles: null,
                working_status: null,
            };
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            sendSmsServices.getStaffSearch(config)
                .then(response => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total

                    this.datatable.isLoading = false

                }).catch(err => {
                this.showErrors(err)
            })
        },
        openModal() {
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
        closeModal() {
            this.form = {}
            this.formProcess = null
            this.$refs.showModal.$refs.commonModal.hide()
        },
        sendSms() {
            const isValid=this.$refs.form.validate()
            if (!isValid){
                return
            }
            const formData = {
                filter: {
                    ...this.datatable.queryParams.filter
                },
                ...this.form
            }
            sendSmsServices.sendSmsStaff(formData)
                .then(response => {
                    this.$toast.success(this.$t("api." + response.data.message))
                    this.closeModal()
                }).catch(err => {
                this.showErrors(err)
            })

        }
    },
};
</script>

